import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue;
    }
    if (key.startsWith('wallcovering-app_calculationInputState_')) {
      return {
        ...JSON.parse(storedValue),
        // bolt_size_yards: 30.0,
        additional_yardage_factor: 0.0,
      }
    }
    return JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(JSON.parse(e.newValue));
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue, callback) => {
    setValue((currentValue) => {
      const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;

      localStorage.setItem(key, JSON.stringify(result));
      if (callback && typeof callback === 'function') {
        callback(result);
      }
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
