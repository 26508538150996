import {createContext, useEffect, useReducer, useState} from "react";
import {defaultCalculatorSettings} from "../config";
import PropTypes from "prop-types";
import axios from '../utils/axios'

const initialState = {
    settings: null,
    calculationResults: {},
}

const handlers = {
    UPDATE_SETTINGS: (state, action) => {
        const { settings } = action.payload
        return {
            ...state,
            settings: {
                ...settings,
            }
        }
    },
    UPDATE_CALCULATION_RESULT: (state, action) => {
        const { calculationType, result } = action.payload
        return {
            ...state,
            calculationResults: {
                ...state.calculationResults,
                [calculationType]: {
                    ...result,
                },
            }
        }
    }
}

const reducer = (state, action) => {
    return handlers[action.type] ? handlers[action.type](state, action) : state
}

const CalculatorsContext = createContext({
    ...initialState,
    updateSettingsKey: () => Promise.resolve(),
    createSettings: () => Promise.resolve(),
    calculate: (calculatorType, keywordArguments) => Promise.resolve(),
    sendFeedback: (email, message) => Promise.resolve()
});

CalculatorsProvider.propTypes = {
    children: PropTypes.node,
}

function CalculatorsProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)
    // Use effect will run once on component mount. It will run once because of the empty array [] in
    // useEffect's second argument. When it runs, it will try to get a settings object.
    // If it fails, it will set defaults.
    useEffect(() => {
        const initialize = async () => {
            try {
                const response = await axios.get('api/v1/calculator/settings/')
                const {settings} = response.data;
                dispatch({
                    type: 'UPDATE_SETTINGS',
                    payload: {
                        settings,
                    }
                })
            } catch (err) {
                const response = await axios.post(
                    'api/v1/calculator/settings/create',
                    {settings: {...defaultCalculatorSettings}},
                )
                const {settings} = response.data;
                dispatch({
                    type: 'UPDATE_SETTINGS',
                    payload: {
                        settings: {...settings},
                    }
                })
            }
        }
        initialize();
    }, [])

    const createSettings = async (settings) => {
        const response = await axios.post(
            '/api/v1/calculator/settings/create',
            {settings: settings},
        )
        const responseSettings = response.data['settings']
        dispatch({
            type: 'UPDATE_SETTINGS',
            payload: {
                settings: {...responseSettings},
            }
        });
    }
    const updateSettingsKey = async (settingsPartial) => {

        const response = await axios.patch(
            '/api/v1/calculator/settings/',
            {
                settings: Object.fromEntries(
                    Object.entries({
                    ...defaultCalculatorSettings,
                    ...state.settings,
                    ...settingsPartial}).filter(
                        keyAndObject => defaultCalculatorSettings.hasOwnProperty(keyAndObject[0])
                    )
                )
            },
        )
        const responseSettings = response.data['settings']
        dispatch({
            type: 'UPDATE_SETTINGS',
            payload: {
                settings: {...responseSettings},
            }
        });

    }


    const calculate = async (calculationType, keywordArguments) => {
        try {
            const response = await axios.post(
            `/api/v1/calculator/${calculationType}/`,
                keywordArguments,
            )
            const calculationResult = response.data
            dispatch({
                type: 'UPDATE_CALCULATION_RESULT',
                payload: {
                    calculationType: calculationType,
                    result: calculationResult
                }
            })
        } catch (error) {
            if ('error' in error) {
                console.log('Error during "calculate", error.error:', error.error);
                dispatch({
                    type: 'UPDATE_CALCULATION_RESULT',
                    payload: {
                        calculationType: calculationType,
                        result: {
                            total_cost_fabric: 0,
                            total_cost_vinyl: 0,
                            price_per_yard_total_cost_fabric: 0,
                            price_per_yard_total_cost_vinyl: 0,
                        },
                    }
                })
            }
            else {
                console.log('Error during "calculate:"', error);
            }
        }
    }

    const sendFeedback = async (email, message) => {
        const response = await axios.post(
            `/api/v1/calculator/feedback`,
            {email: email, message: message},
        )
        const feedbackRequestResponse = response.data
        console.log(feedbackRequestResponse)
    }

    return (
        <CalculatorsContext.Provider value={{
            ...state,
            updateSettingsKey,
            createSettings,
            calculate,
            sendFeedback,
        }}>
            {state.settings && children}
        </CalculatorsContext.Provider>
    )

}

export { CalculatorsProvider, CalculatorsContext }